import React from 'react'
import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTags } from '@fortawesome/free-solid-svg-icons'

const TagsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;

  .fa-tags {
    margin-right: 10px;
    color: ${(props) => props.theme.colors.softBlue};
  }
`

const StyledList = styled.ul`
  margin: 0;

  > li {
    display: inline-block;
    list-style: unset;
    margin-left: unset;
    border-radius: 10px;
    border: 1px solid;
    opacity: 0.6;
    background-color: ${(props) => props.theme.colors.backgroundGrey};
    padding: 3px 10px;
    color: ${(props) => props.theme.colors.textWhite};
    font-size: 0.8em;

    &:hover {
      opacity: 0.9;
      color: ${(props) => props.theme.colors.softBlue};
    }

    + li {
      margin-left: 10px;
    }
  }
`

const Tags = ({ tags }) => {
  if (!tags) return null

  if (typeof tags === 'string') tags = tags.split(', ')

  tags = [...new Set(tags)]

  return (
    <TagsContainer>
      <FontAwesomeIcon icon={faTags} size="xs" />

      <StyledList>
        {tags.map((tag) => (
          <li key={tag}>{tag}</li>
        ))}
      </StyledList>
    </TagsContainer>
  )
}

export default Tags
