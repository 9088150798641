import React from 'react'
import styled from 'styled-components'
import { useForm } from 'react-hook-form'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUndo } from '@fortawesome/free-solid-svg-icons'
import toast from 'react-hot-toast'

const StyledForm = styled.form`
  input[type='submit'] {
    margin-top: 40px;
    border: none;
    background: ${({ theme }) => theme.colors.rosyBrown};
    padding: 15px;
    font-weight: bold;
    text-transform: uppercase;
    font-size: 0.9em;
    letter-spacing: 0.2em;
    color: white;
    cursor: pointer;
  }
`

const CloseFormButton = styled.button`
  margin: 0 auto;
  border-radius: 50%;
  width: 40px;
  height: 40px;
`

const FormField = styled.div`
  display: flex;
  flex-direction: column;
  margin: 20px 0 0;

  label {
    font-size: 0.85em;
    color: ${({ theme }) => theme.colors.textWhite};
  }

  input,
  textarea {
    border: none;
    background-color: ${({ theme }) => theme.colors.backgroundGrey};
    padding: 10px;
    line-height: 1.4;
    font-size: 1em;
    color: ${({ theme }) => theme.colors.textLight};
    resize: none;
  }

  span[role='alert'] {
    margin-top: 3px;
    font-size: 0.75em;
    font-weight: bold;
    color: ${({ theme }) => theme.colors.rosyBrown};
  }
`

const ContactForm = ({ contactType, setContactType }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm()

  const onSubmit = (data, e) => {
    e.preventDefault()

    fetch('https://eoflx7v6tq8exp9.m.pipedream.net', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ contactType, ...data }),
    })
      .then(() => {
        setContactType(null)
        toast(`Hi ${data.name}!\n\nLook for a follow up email soon.`, {
          icon: '👏',
          style: {
            borderRadius: '10px',
            background: '#333',
            color: '#fff',
          },
        })
      })
      .catch((error) => alert(error))
  }

  return (
    <StyledForm
      method="post"
      action="/"
      name="contact"
      onSubmit={handleSubmit(onSubmit)}
    >
      <CloseFormButton onClick={() => setContactType(null)}>
        <FontAwesomeIcon icon={faUndo} />
      </CloseFormButton>

      <FormField>
        <label htmlFor="name">Name</label>
        <input
          id="name"
          type="text"
          aria-invalid={errors.name ? 'true' : 'false'}
          {...register('name', { required: true })}
        />
        {errors.name && errors.name.type === 'required' && (
          <span role="alert">Please enter your name</span>
        )}
      </FormField>

      <FormField>
        <label htmlFor="email">Email</label>
        <input
          id="email"
          type="text"
          aria-invalid={errors.email ? 'true' : 'false'}
          /* eslint-disable no-useless-escape */
          {...register('email', {
            required: true,
            pattern:
              /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
          })}
          /* eslint-enable no-useless-escape */
        />
        {errors.email && errors.email.type === 'required' && (
          <span role="alert">Please enter your email address</span>
        )}
        {errors.email && errors.email.type === 'pattern' && (
          <span role="alert">Please enter a valid email address</span>
        )}
      </FormField>

      {contactType === 'merchant' && (
        <>
          <FormField>
            <label htmlFor="shopName">Shop Name</label>
            <input
              id="shopName"
              type="text"
              aria-invalid={errors.shopName ? 'true' : 'false'}
              {...register('shopName', { required: true })}
            />
            {errors.shopName && errors.shopName.type === 'required' && (
              <span role="alert">Please enter your shop name</span>
            )}
          </FormField>

          <FormField>
            <label htmlFor="projectSummary">Project Summary</label>
            <textarea
              id="projectSummary"
              rows="5"
              aria-invalid={errors.projectSummary ? 'true' : 'false'}
              {...register('projectSummary', { required: true })}
            />
            {errors.projectSummary &&
              errors.projectSummary.type === 'required' && (
                <span role="alert">Please enter a brief project summary</span>
              )}
          </FormField>
        </>
      )}

      {contactType === 'shopify' && (
        <>
          <FormField>
            <label htmlFor="role">Role</label>
            <input
              id="role"
              type="text"
              aria-invalid={errors.role ? 'true' : 'false'}
              {...register('role', { required: true })}
            />
            {errors.role && errors.role.type === 'required' && (
              <span role="alert">Please enter your role at Shopify</span>
            )}
          </FormField>

          <FormField>
            <label htmlFor="projectSummary">Project Summary</label>
            <textarea
              id="projectSummary"
              rows="5"
              aria-invalid={errors.projectSummary ? 'true' : 'false'}
              {...register('projectSummary', { required: true })}
            />
            {errors.projectSummary &&
              errors.projectSummary.type === 'required' && (
                <span role="alert">Please enter a brief project summary</span>
              )}
          </FormField>
        </>
      )}

      {contactType === 'agency' && (
        <>
          <FormField>
            <label htmlFor="agencyName">Agency Name</label>
            <input
              id="agencyName"
              type="text"
              aria-invalid={errors.agencyName ? 'true' : 'false'}
              {...register('agencyName', { required: true })}
            />
            {errors.agencyName && errors.agencyName.type === 'required' && (
              <span role="alert">Please enter your agency name</span>
            )}
          </FormField>

          <FormField>
            <label htmlFor="projectSummary">Project Summary</label>
            <textarea
              id="projectSummary"
              rows="5"
              aria-invalid={errors.projectSummary ? 'true' : 'false'}
              {...register('projectSummary', { required: true })}
            />
            {errors.projectSummary &&
              errors.projectSummary.type === 'required' && (
                <span role="alert">Please enter a brief project summary</span>
              )}
          </FormField>
        </>
      )}

      {contactType === 'developer' && (
        <>
          <FormField>
            <label htmlFor="questionComment">Question or Comment</label>
            <textarea
              id="questionComment"
              rows="5"
              aria-invalid={errors.questionComment ? 'true' : 'false'}
              {...register('questionComment', { required: true })}
            />
            {errors.questionComment &&
              errors.questionComment.type === 'required' && (
                <span role="alert">Please enter a brief project summary</span>
              )}
          </FormField>
        </>
      )}

      <input type="submit" />
    </StyledForm>
  )
}

export default ContactForm
