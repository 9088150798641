import React from 'react'
import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons'

const StyledLink = styled.a`
  svg {
    margin-left: 10px;
  }
`

const ExternalLink = ({ href, children }) => (
  <StyledLink href={href} target="_blank" rel="noopener noreferrer">
    {children}
    <FontAwesomeIcon icon={faExternalLinkAlt} size="xs" />
  </StyledLink>
)

export default ExternalLink
