import React, { useState } from 'react'
// import { Link } from 'gatsby'
import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronCircleRight } from '@fortawesome/free-solid-svg-icons'
import ContactForm from './ContactForm'
import ExternalLink from './ExternalLink'

const StyledSection = styled.section`
  margin: 40px 0;
`

const ContactType = styled.div`
  margin: 20px 0;
`

const ContactTypeHeader = styled.div`
  svg {
    margin-right: 15px;
    color: ${({ theme }) => theme.colors.rosyBrown};
  }
`
const StyledIcon = styled(FontAwesomeIcon)`
  transition: all 0.3s;
  ${(props) => props.$contactOpen && 'transform: rotate(45deg);'}
`

const ContactTypeButton = styled.button`
  background: transparent;
  box-shadow: 0px 0px 0px transparent;
  border: 0px solid transparent;
  text-shadow: 0px 0px 0px transparent;
  font-family: ${({ theme }) => theme.fonts.sans};
  font-size: 1.5em;
  color: ${({ theme }) => theme.colors.textYellow};
  cursor: pointer;

  button:active {
    outline: none;
    border: none;
  }

  button:focus {
    outline: 0;
  }
`

const Intro = styled.div`
  margin: 20px 0;
`

const ContactFlow = () => {
  const [contactType, setContactType] = useState(null)

  return (
    <StyledSection>
      <ContactType>
        {(!contactType || contactType === 'merchant') && (
          <ContactTypeHeader>
            <StyledIcon
              icon={faChevronCircleRight}
              $contactOpen={contactType !== null}
            />
            I am a
            <ContactTypeButton onClick={() => setContactType('merchant')}>
              merchant
            </ContactTypeButton>
          </ContactTypeHeader>
        )}

        {contactType === 'merchant' && (
          <Intro>
            Excellent, so very glad you're here. If you need assistance with any
            of the following, please fill out the contact form and we can set up
            a free initial consultation web session to discuss your project(s).
            <ul>
              <li>Developing a completely custom Mechanic automation</li>
              <li>
                Customizing an existing automation from the{' '}
                <ExternalLink href={`https://tasks.mechanic.dev`}>
                  Mechanic public task library
                </ExternalLink>
              </li>
              <li>Ongoing process improvement consulting</li>
              <li>Shopify API development needs</li>
            </ul>
          </Intro>
        )}
      </ContactType>

      <ContactType>
        {(!contactType || contactType === 'shopify') && (
          <ContactTypeHeader>
            <StyledIcon
              icon={faChevronCircleRight}
              $contactOpen={contactType !== null}
            />
            <span>
              I work for
              <ContactTypeButton onClick={() => setContactType('shopify')}>
                Shopify
              </ContactTypeButton>
              as an MSM or Launch Engineer
            </span>
          </ContactTypeHeader>
        )}
        {contactType === 'shopify' && (
          <Intro>
            Tekhaus has extensive experience working with a wide variety of
            Shopify merchants. Let's connect and cooperate on propelling
            merchants toward success!
          </Intro>
        )}
      </ContactType>

      <ContactType>
        {(!contactType || contactType === 'agency') && (
          <ContactTypeHeader>
            <StyledIcon
              icon={faChevronCircleRight}
              $contactOpen={contactType !== null}
            />
            <span>
              I work for an
              <ContactTypeButton onClick={() => setContactType('agency')}>
                agency
              </ContactTypeButton>
              representing Shopify Plus clients
            </span>
          </ContactTypeHeader>
        )}
        {contactType === 'agency' && (
          <Intro>
            Tekhaus frequently partners with agencies to provide technical
            expertise in using Mechanic to solve some often fairly unique client
            requirements. Let's have a chat to see what all problems we can
            solve together!
          </Intro>
        )}
      </ContactType>

      <ContactType>
        {(!contactType || contactType === 'developer') && (
          <ContactTypeHeader>
            <StyledIcon
              icon={faChevronCircleRight}
              $contactOpen={contactType !== null}
            />
            <span>
              I am a
              <ContactTypeButton onClick={() => setContactType('developer')}>
                developer
              </ContactTypeButton>
            </span>
          </ContactTypeHeader>
        )}
        {contactType === 'developer' && (
          <Intro>
            <p>
              Thank you for stopping by. If you're familiar with the Shopify
              ecosystem and Liquid, then you should give Mechanic a try (hint:
              there are{' '}
              <ExternalLink href="https://learn.mechanic.dev/faq/do-you-have-a-partner-friendly-plan">
                partner-friendly plans
              </ExternalLink>
              , technically super-extended trials, but friendly nonetheless).
            </p>
            <p>
              Be sure to digest as much of Mechanic's{' '}
              <ExternalLink href="https://learn.mechanic.dev/">
                documentation
              </ExternalLink>{' '}
              as you can, as it provides a wealth of information on how to get
              started developing automations on the platform, and is an
              invaluable daily reference for all the bits you can't keep in your
              head.
            </p>
            {/* <p>
              And hopefully, the Mechanic <Link to="/mechanic/tips">tips</Link>{' '}
              and <Link to="/mechanic/tutorials">tutorials</Link> on this site
              will be helpful to you as well.
            </p> */}
            <p>
              <strong>Note: Tekhaus is not hiring.</strong>
            </p>
          </Intro>
        )}
      </ContactType>

      {contactType && (
        <ContactForm
          contactType={contactType}
          setContactType={setContactType}
        />
      )}
    </StyledSection>
  )
}

export default ContactFlow
