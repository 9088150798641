import React from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'
import Tags from './Tags'

const StyledList = styled.ul`
  margin-top: 40px;
  width: 100;
  > li {
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    padding-bottom: 10px;
    + li {
      margin-top: 20px;
    }
  }
`

const TipInfo = styled.div`
  @media (min-width: 1024px) {
    display: flex;
    justify-content: space-between;
  }

  margin-bottom: 5px;
  padding-bottom: 5px;
`

const TipLink = styled.div`
  margin-bottom: 10px;

  @media (min-width: 1024px) {
    margin-bottom: 0;
  }
`

const Excerpt = styled.p`
  margin: 15px 0;
  border-radius: 10px;
  background-color: rgba(221, 238, 255, 0.05);
  padding: 10px;
  font-size: 0.9em;
  box-shadow: 0 1px 2px 0 rosybrown;
`

const TipsList = ({ tips }) => (
  <StyledList>
    {tips.map(({ node: tip }) => (
      <li key={tip.id}>
        <TipInfo>
          <TipLink>
            <Link to={`/${tip.slug}`}>{tip.frontmatter.title}</Link>
          </TipLink>
          <span>{tip.frontmatter.description}</span>
        </TipInfo>
        {tip.excerpt && <Excerpt>{tip.excerpt}</Excerpt>}
        <Tags tags={tip.frontmatter.tags} />
      </li>
    ))}
  </StyledList>
)

export default TipsList
