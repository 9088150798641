exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-agencies-mdx": () => import("./../../../src/pages/agencies.mdx" /* webpackChunkName: "component---src-pages-agencies-mdx" */),
  "component---src-pages-case-studies-mdx": () => import("./../../../src/pages/case-studies.mdx" /* webpackChunkName: "component---src-pages-case-studies-mdx" */),
  "component---src-pages-contact-mdx": () => import("./../../../src/pages/contact.mdx" /* webpackChunkName: "component---src-pages-contact-mdx" */),
  "component---src-pages-developers-mdx": () => import("./../../../src/pages/developers.mdx" /* webpackChunkName: "component---src-pages-developers-mdx" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-mechanic-mdx": () => import("./../../../src/pages/mechanic.mdx" /* webpackChunkName: "component---src-pages-mechanic-mdx" */),
  "component---src-pages-mechanic-tips-action-events-mdx": () => import("./../../../src/pages/mechanic/tips/action-events.mdx" /* webpackChunkName: "component---src-pages-mechanic-tips-action-events-mdx" */),
  "component---src-pages-mechanic-tips-bulk-operation-queries-mdx": () => import("./../../../src/pages/mechanic/tips/bulk-operation-queries.mdx" /* webpackChunkName: "component---src-pages-mechanic-tips-bulk-operation-queries-mdx" */),
  "component---src-pages-mechanic-tips-code-editing-mdx": () => import("./../../../src/pages/mechanic/tips/code-editing.mdx" /* webpackChunkName: "component---src-pages-mechanic-tips-code-editing-mdx" */),
  "component---src-pages-mechanic-tips-mdx": () => import("./../../../src/pages/mechanic/tips.mdx" /* webpackChunkName: "component---src-pages-mechanic-tips-mdx" */),
  "component---src-pages-mechanic-tips-multiline-assignments-mdx": () => import("./../../../src/pages/mechanic/tips/multiline-assignments.mdx" /* webpackChunkName: "component---src-pages-mechanic-tips-multiline-assignments-mdx" */),
  "component---src-pages-mechanic-tips-order-editing-mdx": () => import("./../../../src/pages/mechanic/tips/order-editing.mdx" /* webpackChunkName: "component---src-pages-mechanic-tips-order-editing-mdx" */),
  "component---src-pages-mechanic-tutorials-mdx": () => import("./../../../src/pages/mechanic/tutorials.mdx" /* webpackChunkName: "component---src-pages-mechanic-tutorials-mdx" */),
  "component---src-pages-mechanic-tutorials-using-explicit-rest-mdx": () => import("./../../../src/pages/mechanic/tutorials/using-explicit-rest.mdx" /* webpackChunkName: "component---src-pages-mechanic-tutorials-using-explicit-rest-mdx" */),
  "component---src-pages-merchants-mdx": () => import("./../../../src/pages/merchants.mdx" /* webpackChunkName: "component---src-pages-merchants-mdx" */),
  "component---src-pages-shopify-mdx": () => import("./../../../src/pages/shopify.mdx" /* webpackChunkName: "component---src-pages-shopify-mdx" */)
}

